import React, { useState, useEffect, useContext, useMemo } from 'react'
import Layout from '../../components/Layout'
import { Context } from '../../utils/Store'
import { Alert } from "@material-ui/lab"
import {
    CssBaseline, Grid, makeStyles, Button, Snackbar,
} from '@material-ui/core';
import moment from 'moment'
import FunkyInput from "../../components/FunkyInput"
import { FaPlusCircle } from 'react-icons/fa';
import CKEditor from 'ckeditor4-react';
import Contact from './Contact'
import FilesList from './FilesList'
import { RichCard } from '../../components/RichCard'
import ModalUpload from './ModalUpload'
import { useQuery, useMutation } from '@apollo/client';
import { QUERY_ENTRY, MUTATION_ENTRY, MUTATION_CREATE_FILE } from './_graphql'
import uploadHelper from "../../utils/uploadHelper"
import { WorkflowsList } from './Workflow'
import AddWorkflowModal from './Workflow/AddWorkflowModal';
import DocHeader from './DocHeader';
import Boxy from '../../components/Boxy';
import ReactQuill, {Quill} from 'react-quill';
// import 'react-quill/dist/quill.snow.css';




const useStyles = makeStyles(theme => ({
    root: { flexGrow: 1, marginTop: "-15px" },
}))


const Editor = new Quill("#editor", {
    modules: {
        toolbar: [['bold', 'italic'], ['link', 'image']]
    }
})

const Document = props => {
    const referinta = React.useRef()
    const classes = useStyles()
    const [item, setItem] = useState()
    const [modal, setModal] = useState({ data: { entryID: 1, userID: 1 } })
    const [context, dispatch] = useContext(Context)
    const [id, setId] = useState(null)
    const [err, setErr] = useState()
    const [success, setSuccess] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [scadenta, setScadenta] = useState(0)



    useEffect(() => setId(props.match.params.id), [props.match.params])


    const { error, loading, data, refetch } = useQuery(QUERY_ENTRY, {
        variables: { id: id }, skip: !Boolean(id),
        fetchPolicy: 'network-only'
    })
    // const [queryEntry, dispatcher] =  useQuery(QUERY_ENTRY)

    const [mutateEntry, { dispatchMutationEntry }] = useMutation(MUTATION_ENTRY)

    const [createFileGraph, { dispatchCreateFile }] = useMutation(MUTATION_CREATE_FILE)



    const submitHandler = async () => {
        console.log(item)
        let entryresult, err;
        try {
            entryresult = await mutateEntry({
                variables: { ...item }
            })

        } catch (error) {
            err = error
        } finally {

            if (!err) {
                refetch()
                setSuccess(true)
            } else { setErr(err.toString()) }
        }
    }

    useMemo(() => {
        if (data && data.entry) {
            // if (data.entry.workflow) {
            //     data.entry.workflow = multiSort(data.entry.workflow, { dt: 'desc' }, true)
            // }
            setItem({ ...data.entry })
            // set scadenta(days)
            const start = data.entry.dt ? moment(data.entry.dt) : moment(new Date())
            const end = data.entry.deadline ? moment(data.entry.deadline) : moment((new Date)).add(30, 'days')
            setScadenta(end.diff(start, 'days'))

            if (data.entry.userID.toString() !== context.user.id.toString()) { setDisabled(true) }
        }
    }, [data])




    const inputHandler = name => ev => setItem({ ...item, [name]: ev.target.value })

    const dtHandler = ev => {
        setItem({ ...item, dt: moment(ev.target.value).toDate() })
        let end = item.deadline ? moment(item.deadline) : moment(new Date()).add(30, 'days')
        setScadenta(end.diff(moment(ev.target.value), 'days'))
    }
    const switchHandler = () => setItem({ ...item, direction: item.direction === "in" ? "out" : "in" })
    // const ckEditorHandler = (content) => setItem({ ...item, desc: content.editor.getData() })

    const quillHandler = val => setItem({ ...item, desc: val })


    const deadLineHandler = ev => {
        setItem({ ...item, deadline: moment(ev.target.value).toDate() })
        setScadenta(moment(ev.target.value).diff(moment(item.dt), 'days'))
    }


    const uploadModalHandler = async files => {
        console.log("fle:", files)
        if (typeof files === "undefined") { setModal(false) } else {
            let uploads, err;
            try {
                uploads = await uploadHelper(files)
            } catch (error) {
                err = error
            } finally {
                if (err) {
                    setErr(err)
                } else {
                    // console.log("else:",uploads)
                    let filesWithEntryId = uploads.map(elem => ({ ...elem, entryID: item.id }))
                    let qraphs = filesWithEntryId.map(elem => createFileGraph({ variables: elem }))
                    Promise.all(qraphs).then(
                        r => { setItem({ ...item, files: [...item.files, ...filesWithEntryId] }); setModal(false) },
                        err => { setErr(err); setModal(false) }
                    )

                }
            }
        }
    }

    const newWorkflowItemHandler = ev => {
        console.log("ev:", ev)
        if (!ev) { setModal() } else {
            console.log("passed not null back to component!", ev)
            refetch()
            setModal()
        }

    }

    const childHandler = ev => setItem(ev)
        
    const dueHandler = ev => setScadenta(ev)


    return (
        <div>
            <CssBaseline />

            {item && (
                <Layout>
                    {error && <Alert severity="error">{error.toString()} </Alert>}
                    {err && <Alert severity="error">{err} </Alert>}
                    {loading && <Alert severity="success">se încarcă datele...</Alert>}
                    {/* <CssBaseline /> */}
                    <div style = {{height:"6px"}}/>
                    <Grid container className={classes.root} spacing={1}>
                        <Grid item xs={12}>
                            <div style = {{marginLeft: "10px", marginRight: "10px"}}>
                            <DocHeader 
                                data = {item} 
                                onChange = {childHandler} 
                                disabled = {disabled}
                                dueChange = {dueHandler}
                                onSubmit ={submitHandler}
                            />
                            </div>
                            <div style = {{ margin: "10px"}}>
                             

                                <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
                                    {/* <Grid item xs={12}>
                                        <FunkyInput
                                            value={item.title}
                                            label="SUBIECT"
                                            onChange={inputHandler('title')}
                                            disabled={disabled}
                                        />
                                    </Grid> */}
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item sm={12} xs = {12}>
                                                <Grid container justify="space-between" alignItems="flex-start" spacing={1}>
                                                    <Grid item xs={12}>
                                                        {/* <RichCard style={{ height: "25vh" }}
                                                            title="PARTENER / PETENT"
                                                        > */}
                                                        <Boxy title = "PARTENER" style = {{background: "LavenderBlush"}}>
                                                            <Contact
                                                                data={{ ...item.contact }}
                                                                disabled={disabled}
                                                                onChange={event => {
                                                                    console.log("event:", event)
                                                                    setItem({
                                                                        ...item,
                                                                        contact: event || {},
                                                                        contactID: event && event.id
                                                                    },
                                                                    )
                                                                }
                                                                }
                                                            />
                                                            </Boxy>
                                                        {/* </RichCard> */}
                                                    </Grid>
                                                    <Grid item sm={5} xs = {12}>
                                                        {/* <RichCard title="DESCRIERE SUMARĂ" style={{ height: "32vh" }}> */}
                                                      <Boxy 
                                                        title = "DESCRIEREA SUMARĂ A DOCUMENTULUI"
                                                        style = {{height: "38vh"}}
                                                      >
                                                            {!disabled ? (
                            
                                                                <ReactQuill 
                                                                    modules={
                                                                        { toolbar: [

                                                                            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                                                                            [ 'code-block'],
                                                                          
                                                                            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                                                                            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                                                            [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                                                                            // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                                                                            // [{ 'direction': 'rtl' }],                         // text direction
                                                                          
                                                                            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                                                                            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                                                          
                                                                            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                                                                            // [{ 'font': [] }],
                                                                            [{ 'align': [] }],
                                                                            ['clean']   
                                                                        ]}
                                                                    }
                                                                    height="50px"
                                                                    background="white"
                                                                    theme="snow" 
                                                                    value={item.desc || ""} 
                                                                    style = {{height: "24vh", background: "white"}}
                                                                    onChange={quillHandler}/>
                                                                
                                                            ) : (
                                                                    <div dangerouslySetInnerHTML={{ __html: item.desc || "" }} />
                                                                )}

                                                        {/* </RichCard> */}
                                                        </Boxy>
                                                    </Grid>
                                                    <Grid item sm={4} xs = {12}>
                                                        <RichCard
                                                            title={`FIȘIERE ATAȘATE (${item.files.length})`}
                                                            style={{ height: "38vh"  }}
                                                            contentStyle = {{height: "37vh", overflow: "auto"}}
                                                            
                                                            action={
                                                                <Button
                                                                    size="small"
                                                                    onClick={() => setModal({
                                                                        type: "upload",
                                                                        data: {
                                                                            userID: context.user.id,
                                                                            entryID: item.id
                                                                        }
                                                                    })}
                                                                >
                                                                    <FaPlusCircle style={{ color: "red", fontSize: '1.5em' }} />
                                                                </Button>
                                                            }
                                                        >
                                                            <FilesList
                                                                data={item.files}
                                                                onChange={ev => setItem({ ...item, files: ev })}
                                                            />
                                                        </RichCard>
                                                    </Grid>
                                                    <Grid item xs>
                                                    <RichCard
                                                    style={{ height: "38vh" }}
                                                    contentStyle = {{height: "30vh", overflow: "auto"}}
                                                    title="ACTIVITATE"
                                                    action={
                                                        <Button size="small" onClick={() => setModal({
                                                            type: "workflow",
                                                            data: item
                                                        })}>
                                                            <FaPlusCircle style={{ color: "red", fontSize: '1.5em' }} />
                                                        </Button>
                                                    }
                                                >
                                                    <WorkflowsList {...item} />
                                                </RichCard>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
     
                                        </Grid>
                                    </Grid>
                                </Grid>


                            </div>

                        </Grid>
                    </Grid>

                    {modal && modal.type === "upload" &&
                        <ModalUpload data={modal && modal.data} onClose={uploadModalHandler} />
                    }

                    {modal && modal.type === "workflow" && (
                        <AddWorkflowModal data={modal && modal.data} onClose={newWorkflowItemHandler} />
                    )}
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={success}
                        autoHideDuration={2000}
                        onClose={() => { setSuccess(false) }}>
                        <Alert severity="info">
                            MODIFICĂRILE AU FOST EFECTUATE CU SUCCES!
                            </Alert>
                    </Snackbar>

                    

                </Layout>
            )}

        </div>
    )




}

export default Document