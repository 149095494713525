import { useState, useEffect, useContext } from 'react'
import { Context } from '../../utils/Store'
import { useLazyQuery, useQuery } from '@apollo/client'
import { PAGINATED_REPORT } from './_graphql'
import { Grid, LinearProgress, Toolbar, makeStyles, IconButton, TextField, MenuItem, Button } from '@material-ui/core'
import Reg from './Reg'
import { Fragment } from 'react'
import { FaChevronCircleLeft, FaChevronCircleRight, FaSearch } from 'react-icons/fa'
import { append } from '../../utils/ls'
import RegFilters from './RegFilters'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
    toolbar: {
        background: theme.palette.grey[0]
    },
    inputProps: {
        fontSize: "11px", fontWeight: 800
    }
}))

const PaginatedList = props => {
    const classes = useStyles()
    
    const [state, setState] = useContext(Context)
    const [params, setParams] = useState({ like: [], rowStart:0, rowEnd: 10 })
    const [entries, setEntries] = useState([])
    const [filterVisible, setFilterVisible] = useState(false)
    const [curYear, setCurYear] = useState(moment().format("YYYY"))

    const initialParams = {
        between: {row: "dt", start: moment().startOf("year").toDate(), end: moment().endOf("year").toDate()},
        chunk: { offset: 0, limit: state.user.perPage || 15 },
        ordering: [
            // { row: "dt", order: "DESC" }, 
            { row: "nr", order: "DESC" }]
    }

    useEffect(() => {
        setParams(initialParams)
    }, [])

    // useEffect(() => {
    //     ql({ variables: { data: params } })
    // }, [params])
    const {data, loading, error, refetch, networkStatus} = useQuery(PAGINATED_REPORT, {
        variables: {
            data: params
        },
        fetchPolicy: "cache-and-network"
    })

    // const [ql, { data, loading, error }] = useLazyQuery(PAGINATED_REPORT)

    useEffect(() => {
        data && data.report && setEntries(data.report.entries) 
    }, [data])

    const nextPageHandler = ev => {
        ev.preventDefault()
        let p = { ...params, chunk: { offset: params.chunk.offset + state.user.perPage, limit: state.user.perPage } }
        // fetchMore({variables: {data: p}})
        setParams(p)
        // refetch({variables: {data: params}})
    }
    const prevPageHandler = ev => {
        ev.preventDefault()
        setParams({ ...params, chunk: { offset: params.chunk.offset - state.user.perPage, limit: state.user.perPage } })
    }

    const perPageHandler = ev => {
        append({ perPage: ev.target.value })
        setState({...state, user: {...state.user, perPage:ev.target.value}})
        setParams({ ...params, chunk: { offset: 0, limit: ev.target.value } })
    }

    const applyFitersHandler = ev => {
        console.log("Ev:",ev)
        let newParams = {...initialParams}
        if ( ev && ev.like && ev.like.length > 0 ) {
            newParams = {...newParams, like: ev.like}
        }
        if (ev && ev.between ){
            newParams = {...newParams, between: ev.between}
        }
        
        setParams({...initialParams, ...newParams })
        // refetch()
    }

    const childHandler = ev => {
        setEntries(ev.entries)
    }

    const yearHandler = name => () => {
        if ( name !== curYear ) {
            setCurYear(name)
            console.log("name:", name)
            setParams({
                ...params, 
                between: {
                    row: "dt",
                    start: moment(name).startOf("year").toDate(), 
                    end: moment(name).endOf("year").toDate()
                },
                chunk: { offset: 0, limit: state.user.perPage || 10 }
            })
        } 
    }

    return (
        <div style={{ maxHeight: "82vh", overflow: "auto" }}>
            {console.log("st:", state)}
            { error && <pre>{error.toString()}</pre>}
            { loading && <LinearProgress />}
            { data &&
                <Fragment>

                    <Toolbar variant="dense" className={classes.toolbar}>
                        <Grid container justify="space-between" alignItems="center">
                            <Grid item>
                            <Grid container alignItems="center">
                                Se afișează câte&nbsp;
                                <TextField select variant="outlined" size="small" InputProps = {{className: classes.inputProps}}
                                    value={params.chunk.limit} onChange={perPageHandler}
                                >
                                    {[5, 10, 15, 20, 50, 100, 500, 1000].map((e, i) => (
                                        <MenuItem value={e} key={i}>{e}</MenuItem>
                                    ))}
                                </TextField>
                                 &nbsp; rânduri
                                <IconButton size="medium" onClick={prevPageHandler}>
                                    <FaChevronCircleLeft />
                                </IconButton>
                                pagina
                                <IconButton onClick={nextPageHandler}><FaChevronCircleRight /></IconButton>
                                {/* {JSON.stringify(params)} */}
                            </Grid>
                            
                            </Grid>
                            <Grid item>
                                
                                se afișează anul {[ "2021", "2022", "2023", "2024", "2025"].map( (e,i) => (
                                    <Button 
                                        size="small" key={i} 
                                        onClick = {yearHandler(e)}
                                        variant = {e === curYear ? "contained": "outlined"}
                                        color = {e === curYear ? "primary": "default"}
                                    >
                                        {e}
                                    </Button>
                                ))}
                            </Grid>
                            <Grid item>
                                <Button 
                                    variant = "outlined" color = "primary" size = "small"
                                    onClick = {() => setFilterVisible(true)}
                                >
                                    <FaSearch/>&nbsp;&nbsp;FILTRE</Button>
                            </Grid>
                        </Grid>
                    </Toolbar>
                    <div style = {{height: "75vh", overflow: "auto"}}>
                    <Reg entries={entries} onChange = { childHandler }/>
                    </div>
                </Fragment>
            }

            <RegFilters 
                open={filterVisible}
                onClose={() => setFilterVisible(false)} 
                onApply = {applyFitersHandler}
                onReset = {ev => ev ? setParams(initialParams) : null}
            />
        </div>
    )
}

export default PaginatedList